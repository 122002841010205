@import '../../styles/typography.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 400px;
}

.logo {
  margin-bottom: 5px;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-name: fade;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  max-width: 30px;
  height: auto;
}

.text {
  @include termina();
  font-weight: 500;
  font-size: 12px;
  opacity: 0.9;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}
