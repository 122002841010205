@import '../../styles/typography.scss';
@import '../../styles/media.scss';

.item {
  @include termina();
  padding: 21px 12px;
  border: 3px solid transparent;
  background-color: #eee;
  position: relative;
  width: 100%;
  transition: all 200ms ease-out;
}

.item__selected {
  background-color: #fff;
  border-color: rgba(255, 165, 0, 1);
}

.content {
  display: flex;
}

.content__selected {
  flex-direction: column;
  align-items: center;
}

.img {
  width: 70px;
  flex: 0 0 auto;
  cursor: pointer;

  .item__selected & {
    width: 50px;
    margin-bottom: 6px;
  }
}

.text {
  padding: 0 12px;
  flex: 1 1 auto;
}

.buttonPrice {
  flex: 0 0 auto;
  text-align: center;
  @include bp('sm') {
    max-width: 150px; // maybe find a better way to wrap price on 2 lines
  }

  .item__selected & {
    display: flex;
    flex-direction: column-reverse;
  }
}

.title {
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
}

.price {
  font-size: 13px;
}

.price__sale {
  color: #d8363e;
}

.priceCompare {
  opacity: 0.5;
  margin-left: 0.3em;
  white-space: nowrap;
}

.jump {
  font-size: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;

  .item__selected & {
    display: none;
  }
}

.jumpIcon {
  font-size: 1.8em;
}

.button {
  margin-bottom: 12px;
}

.button__disabled {
  color: rgba(0, 0, 0, 0.55) !important;
  background-color: grey;
}

.comments {
  @include roboto;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  background-image: url('./chat_bubble.svg');
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 53px;
  padding-left: 64px;
  margin-top: 6px;
}
