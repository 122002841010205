@import '../../styles/typography.scss';
@import '../../styles/media.scss';

.dividerNoMargin {
  margin: 0;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.options {
  display: flex;
  flex-direction: column;
  padding: 12px;

  @include bp('sm') {
    flex-direction: row;
  }
}

.heading {
  @include termina();
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 24px;
}

.brandImg {
  flex: 0 0 auto;
  width: 100px;
  margin-left: 12px;
}
.brandImgLink {
  display: block;
  background-position: right center;
  background-size: contain;
  height: 100%;
  background-repeat: no-repeat;
}

.imgWrapper {
  margin-left: auto;
  margin-right: auto;
  flex: 0 0 auto;
  padding-right: 12px;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.optionsSelector {
  padding-left: 12px;

  @include bp('sm') {
    width: 50%;
  }
}
.optionsSelector__hasColors {
  padding-left: 0;
}

.fieldset {
  margin-bottom: 12px;
}
.fieldset__colors {
  margin-right: -12px; // break out of the padding
}

.optionLegend,
.stock {
  text-transform: uppercase;
  color: #938883;
  @include termina();
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 18px;
}
.optionLegend {
  margin-bottom: 4px;
}
.optionLegendCurrent {
  color: #000;
}

.optionRadio {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.optionUnavailable {
  opacity: 0.2;
}
.optionSelected {
  box-shadow: 0 0 0 2px #e84b25;
  position: relative;
  z-index: 1;
  color: #e84b25;
}

.colorsWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;
  padding: 2px;
}

.colorLabel {
  position: relative;
  cursor: pointer;
  flex: 0 0 auto;
  flex-basis: 18%;
}

// .colorLabel {
//   display: block;
//   position: relative;
//   cursor: pointer;
//   flex-basis: 18%;
//   margin-right: 2%;

//   @include bp('sm') {
//     flex-basis: 12%;
//   }
// }

.textLabel {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  min-width: 60px;
  text-align: center;
}

.priceQuantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  @include termina();
  margin-bottom: 0;
  color: #657d7d;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.84px;
  line-height: 24px;
}
.priceCompare {
  opacity: 0.5;
  margin-left: 0.3em;
  white-space: nowrap;
}
.price__sale {
  color: #d8363e;
}

.quantityWrapper {
  @include termina();
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  @include bp('sm') {
    margin-bottom: 12px;
  }
}
.quantityInput {
  width: 60px;
  border: 2px solid #000;

  &.disabled {
    border-color: unset;
  }
}

.bottomButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-bottom: 4px;
}

.actionButton:not(:last-child) {
  margin-left: 12px;
}

.newReleaseTag {
  background-color: rgba(43, 237, 4, 0.5);
  border-radius: 2px;
  display: inline-block;
  padding: 0 2px;
  font-weight: normal;
  font-size: 12px;
  margin-left: 0.3em;
  word-break: keep-all;
  vertical-align: middle;
}

.variantTitle {
  @include termina();
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.56px;
  line-height: 18px;
}

.detailLink {
  text-align: center;
}

.allColors {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
}

.allColorsItem {
  width: 40px;
}

.shippingEstimator {
  text-align: center;
}

.fullDetails {
  @include termina();
  font-size: 12px;
  // .orangeText();
}
